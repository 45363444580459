<template>
    <div class="goods-page">
        <div v-if="detailData.order" class="goods-information">
            <div>
                <p>发货单号:</p>
                <p>{{ detailData.order.order_sn }}</p>
            </div>
            <div>
                <p>发货时间:</p>
                <p>{{ detailData.order.delivery_at }}</p>
            </div>
            <div>
                <p>数　量:</p>
                <p>x {{ detailData.order.number }}</p>
            </div>
            <div>
                <p>备　注:</p>
                <p>{{ detailData.order.remark }}</p>
            </div>
        </div>
        <div class="goods-checklist">
            <van-collapse v-model="collapseActive" accordion>
                <van-collapse-item
                    v-for="(item, index) in dataList"
                    :name="index"
                    :title="item.production"
                    class="checklist-item"
                >
                    <p>资产序列号：{{ item.assets }}</p>
                    <p>终端序列号:{{ item.equipment }}</p>
                </van-collapse-item>
            </van-collapse>
        </div>
        <van-button
            v-if="detailData.status === 0"
            :block="true"
            :hairline="false"
            :loading="false"
            class="confirm-button"
            color="#DF5F0B"
            @click="onReseive(detailData.id)"
        >
            确认收货
        </van-button>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { GET_GOODS_RECEIVE_DETAIL, GET_GOODS_RECEIVE_POLE_DETAIL, UPDATE_GOODS_RECEIVE_STATUS } from "@/api/goods";
import usePublic from "@/hooks/usePublic";
import { Dialog, Toast } from "vant";

let { $route, $router, $store } = usePublic();

onMounted(() => {
    getDetail();
});

let collapseActive = ref(null);

// 获取详情
let detailData = ref({});
let dataList = ref([]);

let getDetail = () => {
    GET_GOODS_RECEIVE_DETAIL({ id: $route.params.id }).then((res) => {
        console.log(res.data.data);
        detailData.value = res.data.data;
        GET_GOODS_RECEIVE_POLE_DETAIL({ id: detailData.value.order.id }).then((cres) => {
            console.log(cres.data.data);
            dataList.value = cres.data.data;
        });
    });
};

// 确认收货
let onReseive = (id) => {
    Dialog.confirm({
        title: "提示",
        message: "确认收货？"
    }).then(() => {
        UPDATE_GOODS_RECEIVE_STATUS({ id: id, status: 1 }).then((res) => {
            if (res.data.code === 200) {
                Toast({
                    type: "success",
                    message: "确认收货成功",
                    onClose: () => {
                        $router.go(-1);
                    }
                });
            }
        });
    });
};
</script>

<style lang="scss" scoped>
.goods-page {
    min-height: 100vh;
    padding-bottom: 60px;
    background: #f0f0f0;

    .goods-information {
        min-height: 20vh;
        background: #fff;
        font-size: 12px;
        display: flex;
        flex-direction: column;

        div {
            margin-top: 20px;
            padding: 0 15px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            color: #000;

            p:nth-child(2) {
                color: #999999;
            }
        }

        div:nth-child(4) {
            flex-direction: column;
            margin-bottom: 20px;

            p:nth-child(2) {
                padding-top: 10px;
            }
        }

        div:nth-child(1) {
            p:nth-child(2) {
                color: #df5f0b;
            }
        }
    }

    .goods-checklist {
        min-height: 10vh;
        margin-top: 10px;
        background: #fff;
        padding: 20px 0px;

        .checklist-item {
            p {
                margin: 10px 0;
            }
        }
    }

    .confirm-button {
        position: fixed;
        bottom: 0;
    }
}
</style>
